/*---------- Custom variables ----------*/
:root {
  --primary: #1A8BEB;
  --invisible: #ffffff00;
  --white: white;
  --light: #f3f3f3;
  --gray: #666666;
  --dark: #343a40;
  --primary-gradient: linear-gradient(45deg, #005bdf, #1A8BEB);
  --primary-gradient-r: linear-gradient(45deg, #1A8BEB, #005bdf);
}

/*-----X----- Custom variables -----X-----*/
/*---------- Custom scrollbar ----------*/
::-webkit-scrollbar {
  width: 17px;
}

::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.07);
}

::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.3);
}

::-webkit-scrollbar-thumb:hover {
  background: var(--primary);
}


mat-sidenav-container ::-webkit-scrollbar-track {
  border-radius: 5px;
}

mat-sidenav-container ::-webkit-scrollbar-thumb {
  border-radius: 5px;
}
/*-----X----- Custom scrollbar -----X-----*/

//html, body {
//  height: 100%;
//}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

a {
  text-decoration: none;
  color: black;
}

a:hover {
  color: var(--primary) !important;
  transition: color 0.25s;
}
a.btn:hover{
  color: var(--white) !important;
}
.text-muted-light{
  color: #7c8690;
}
.text-vertical {
  writing-mode: vertical-rl;
}

.btn-gradient-custom {
  color: white;
  background: var(--primary-gradient-r);
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.125);
}

.btn-gradient-custom:hover {
  color: white;
  background: var(--primary-gradient-r);
  border-color: white;
  box-shadow: none;
  transition: 0.25s;
}

.btn-gradient-custom:checked {
  background: black;
  border-color: white;
  transition: 0.25s;
}

.btn-gradient-custom:focus {
  color: white;
  background: var(--primary-gradient-r);
  border-color: transparent;
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.125);
  transition: 0.25s;
}
/*-----X----- Scrollable table -----X-----*/
.tableFixHead {
  overflow: auto;
  height: calc(100% - 99px);
}
.tableFixHead thead th {
  background:#F3F3F3;
  position: sticky;
  top: 0;
  z-index: 1;
}
.tableFixFooter tfoot {
  background:#F3F3F3;
  position: sticky;
  bottom: 0;
  z-index: 1;
}
.text-lightgray{
  color: var(--light);
}
.unselectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.sorter {
  cursor: pointer;
  mat-icon{
    transform: scale(75%);
    position: absolute;
  }
}
.pointer {
  cursor: pointer;
}
.fs-7{
  font-size:0.5rem!important
}

/* Importing Bootstrap SCSS file. */
//@import 'bootstrap/scss/bootstrap';
